import { createReducer, on } from '@ngrx/store';
import * as AlertingActions from './alerting.actions';
import { InfoPagination } from '@models/api-models';
import { DateTimePeriodString } from '@models/dateTimePeriod';
import { DateTime } from 'luxon';
import { AlertFilters } from '@models/alerting-models/alert-filters';
import { AlertHistory, AlertType } from '@models/alerting-models';

export const alertingFeatureKey = 'alerting';

export interface State {
    alertType: AlertType;
    isOnCall: boolean;
    alertsHistory: AlertHistory[];
    alertsPagination: InfoPagination;
    onCallAlertsHistory: AlertHistory[];
    onCallPagination: InfoPagination;
    dateTimePeriod: DateTimePeriodString;
    filters: AlertFilters;
    isFilterActivated: boolean;
}

const initialState: State = {
    alertType: 'Default',
    isOnCall: null,
    alertsHistory: [],
    alertsPagination: null,
    onCallAlertsHistory: [],
    onCallPagination: null,
    dateTimePeriod: {
        period: 'hour',
        start: DateTime.now().minus({ hour: 1 }).set({ millisecond: 0 }).toISO(),
        end: DateTime.now().set({ millisecond: 0 }).toISO()
    },
    filters: {
        withReports: false
    },
    isFilterActivated: false
};

export const reducer = createReducer(
    initialState,
    on(AlertingActions.setAlertType, (state, { alertType: alertType }): State => ({ ...state, alertType: alertType })),
    on(AlertingActions.setDateTimePeriod, (state, { dateTimePeriod }): State => ({
        ...state, dateTimePeriod: {
            period: dateTimePeriod.period,
            start: dateTimePeriod.start.toISO(),
            end: dateTimePeriod.end.toISO()
        }
    })),
    on(AlertingActions.isUserOnCallSuccess, (state, { isOnCall }): State => ({ ...state, isOnCall })),
    on(AlertingActions.setAlertFilters, (state, { filters }): State => ({ ...state, filters: filters, isFilterActivated: filters.withReports })),
    on(AlertingActions.getAlertsHistorySuccess, (state, { alertsHistory: alertsHistory, pagination }): State => ({ ...state, alertsHistory: alertsHistory, alertsPagination: pagination })),
    on(AlertingActions.getOnCallAlertsHistorySuccess, (state, { onCallAlertsHistory: onCallAlertsHistory, pagination }): State => ({ ...state, onCallAlertsHistory: onCallAlertsHistory, onCallPagination: pagination })),

    on(AlertingActions.getAlertHistoryReportsSuccess, (state, { alertId, dateEvent, reports }): State => {
        const alertHistory = [...state.alertsHistory.map(h => {
            if (h.alertId === alertId && h.dateEvent.toMillis() === dateEvent.toMillis())
                return { ...h, reports };
            return h;
        })];
        const onCallHistory = [...state.onCallAlertsHistory.map(h => {
            if (h.alertId === alertId && h.dateEvent.toMillis() === dateEvent.toMillis())
                return { ...h, reports };
            return h;
        })];
        return { ...state, alertsHistory: alertHistory, onCallAlertsHistory: onCallHistory };
    }),

    on(AlertingActions.getNextAlertsHistorySuccess, (state, { alertsHistory: alertsHistory, pagination }): State => {
        const nextAlertHistory = [...state.alertsHistory, ...alertsHistory];
        return { ...state, alertsHistory: nextAlertHistory, alertsPagination: pagination };
    }),

    on(AlertingActions.getNextOnCallAlertsHistorySuccess, (state, { onCallAlertsHistory: onCallAlertsHistory, pagination }): State => {
        const nextAlertHistory = [...state.onCallAlertsHistory, ...onCallAlertsHistory];
        return { ...state, onCallAlertsHistory: nextAlertHistory, onCallPagination: pagination };
    })
);
